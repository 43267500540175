import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import SocketIo from "./services/socket";

import * as SentryX from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

require('react-web-vector-icons/fonts');


/*
SentryX.init({
  dsn: "https://76ddd73fac7549b08a1f033a08e94559@o4504720783835136.ingest.sentry.io/4504720795566080",
  integrations: [new BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});
*/


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
    <SocketIo />
  </React.StrictMode>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
