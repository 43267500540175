import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { StoreContext } from '../App';
import $ from "jquery";
import { getVenue } from '@mappedin/mappedin-js';
import Select from 'react-select'
import SearchBooks from "./SearchBooks";
import Settings from "./Settings";


const HomeScreen = (props) => {

  const navigate = useNavigate();

  const [state, setState] = useState({
    busy: true,
  });

  useEffect(() => {
    $('#mappedin-map').show();
  }, []);

  return (
      <div>
        <SearchBooks/>
      </div>
  )
};

export default HomeScreen;
