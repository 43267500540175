

class MapStore {
  constructor(){
    this.credentials = {
      "clientId": "62fd488d5550ac001c7d3b99",
      "clientSecret": "9ZWBXsaL2RTyV85fngOe0jsDOUZMIIZbvptcb67UVk7HsHLC",
      "venue": "madina-book-fair",
      "lang": "en",
      "themeOverrides": {
        "pathRadius": 1
      }
    };
    //map#/directions?to=&from=
    //this.mapDomain = 'http://localhost:3001/'
    this.mapDomain = 'https://mapdesktop.saudibookfairs.com/'
    //this.mapDomain = 'https://test.saudibookfairs.com/'

    window.mapDomain = this.mapDomain
  }
}

export default MapStore;
