import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { StoreContext } from '../App';
import moment from 'moment'
import $ from 'jquery'

import SearchBooks from './SearchBooks'
import Settings from "./Settings";

const mapDomain = 'https://mapdesktop.saudibookfairs.com/map#/directions?to=&from=';

const Map = (props) => {

  const settingsRef = useRef()
  const myStores = useContext(StoreContext);

  const updateReloadTime = () => {
    setTimeout(()=>{
      //let formatted = moment(new Date()).format("hh:mm:ss");
      localStorage.setItem('mx-map-last-active', moment().unix() );
    }, 50)
  };

  const checkUrlInterVal = () => {
    setInterval(()=>{
      console.log ('-->check interval init' );

      try {
        let fromFromStorage = localStorage.getItem('mx-map-default-location');

        if(!fromFromStorage)
          return;

        fromFromStorage = JSON.parse(fromFromStorage);
        let from = window.location.href.split('from=');
        if(fromFromStorage.value === from[1])
        {
          console.log ('-->ignore same from' );
          return;
        }

        let lastActive = localStorage.getItem('mx-map-last-active');
        if(!lastActive)
          return;

        let storageTime = moment.unix(lastActive);
        let nextTime = storageTime.add(5, 'minutes').unix();
        if(moment().unix() < nextTime  )
        {
          console.log('ignore by time');
          return;
        }

        console.log ('-->will reload' );
        localStorage.setItem('mx-map-last-active', '');
        let defLocation = localStorage.getItem("mx-map-default-location");
        defLocation = JSON.parse(defLocation);
        window.location.href = myStores.mapStore.mapDomain + 'map#/?' + 'directions?to=&from=' + defLocation.value

      } catch (err) {
        console.log ('-->e', err );
      };

    }, 3000);
    try {

    } catch (err) {

    }
  };

  const checkIsUrlChanged = () => {
    setInterval(()=>{
      try
      {
        setTimeout(()=>{
          let profileId = window.location.href.split('location=');
          if(profileId[1])
          {
            let defLocation = localStorage.getItem("mx-map-default-location");
            defLocation = JSON.parse(defLocation);
            //let url = 'https://mapdesktop.saudibookfairs.com/map#/directions?';
            //let url = 'https://map.ribf.app/map#/directions?';
            let url = myStores.mapStore.mapDomain + 'map#/'
            //console.log('profileId=>',profileId[1]);
            window.location.href = `${url}directions?to=${profileId[1]}&from=${defLocation.value}`
          }
        }, 0)
      }
      catch (err){}
    }, 2000)
  };

  const _onKeypress = (e) => {
    console.log ('-->key press a', e,  e.key);
    // win -> ALT K   => settings ctrl K
    if(e.charCode === 44)
      settingsRef.current.show()
    if(e.ctrlKey === true && e.key === 'k')
    {
      console.log ('-->key press K', e );
      settingsRef.current.show()
    }
    // win -> ALT U   => update
    else if(e.ctrlKey === true && e.key === 'u') {
      console.log ('-->key press u', e );
      window.location.reload(false);
    }

    updateReloadTime();
  }

  useEffect(() => {
    console.log ('--> map' );

    console.log ('-->open socket type map' );
    let fromFromStorage = localStorage.getItem('mx-map-default-location');
    if (fromFromStorage)
      myStores.conStore.socketConnection('map', fromFromStorage)

    $('#mappedin-map').show();
    if(!fromFromStorage)
    {
      console.log ('-->No Default Location' );
    }
    else
    {
      checkUrlInterVal();
      checkIsUrlChanged();
      setTimeout( () => {
        console.log('map js done');
        document.addEventListener("click", updateReloadTime) ;
        document.addEventListener("keypress", _onKeypress);
        document.addEventListener("touchmove", updateReloadTime);
        document.addEventListener("touchstart", updateReloadTime);
        document.addEventListener("mousedown", updateReloadTime);

        setTimeout(()=>{
          console.log('sc-eXuyPJ', $("div[aria-label='Go back']").length)
          //aria-label="Go back"
          $("div[aria-label='Go back']").click(()=>{
            console.log('bbback')
          })
        }, 2000)

      }, 100)
    }
    return () => {

    }
  }, []);

  return (
      <div>
        <SearchBooks uri={'map'}/>
        <Settings ref={settingsRef} />
      </div>
  )
};

export default Map;
