import React, { useContext, useEffect, useRef, useState } from 'react';

import _ from 'lodash'
import Button from "react-bootstrap/Button";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import '../App.css';
import { getVenue } from "@mappedin/mappedin-js";
import { StoreContext } from "../App";
const SearchBooks = React.forwardRef((props, ref) => {

  const [show, setShow] = useState(false);
  const [busy, setBusy] = useState(true);
  const [originalBooks, setOriginalBooks] = useState();
  const [books, setBooks] = useState([]);
  const myStores = useContext(StoreContext);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const onSearchBook = (e) => {
    console.log ('-->e', e );
    let filterBooks = originalBooks.filter((obj)=>{
      return obj.name.includes(e.target.value)
    })
    setBooks(filterBooks)
  }
  const onBookClick = (item) => {
    let fromFromStorage = localStorage.getItem('mx-map-default-location');
    fromFromStorage = JSON.parse(fromFromStorage)
    let url = myStores.mapStore.mapDomain;

    if(props.uri)
    {
      url += 'map#/directions?'
    } else {
      url += '#/directions?'
    }

    if(item.map_id)
    {
      url += 'to=' + item.map_id
    }
    if(fromFromStorage)
    {
      url += '&from=' + fromFromStorage.value
    }
    else {
      url += '&from='
    }
    window.location.href = url
    setShow(false)
  }

  const openSearchModal = () => {
    setShow(true)
  }

  const fetchData = async () => {
    console.log ('-->fetchData ' );
    myStores.conStore.getAllBooks().then(res=>{
      console.log ('-->res', res.data );
      setBooks(res.data.Books)
      setOriginalBooks(res.data.Books)
    })
  }

  useEffect(()=>{
    console.log ('-->useEffect start' );


    if(busy)
    {
      setBusy(false)
      fetchData()

    }
  }, [])

  return (
      <div>
        <Modal show={show} onHide={handleClose} style={{zIndex: 9999999999999}} dir={'rtl'}>
          <Modal.Header closeButton>
            <Form.Control type="text" placeholder="ابحث بـ اسم الكتاب" onChange={onSearchBook} />
          </Modal.Header>
          <Modal.Body>
            <ul>
              {books.map((item,index)=>{
                if(index < 1000)
                {
                  return (
                      <li style={{marginTop: 10, cursor: 'pointer'}} onClick={()=>{onBookClick(item)}}>{item.name}</li>
                  )
                }
              })}
            </ul>
          </Modal.Body>
        </Modal>

      <div style={{
        position: 'absolute',
        bottom: 30,
        zIndex: 99999999,
        width: '100%',
        textAlign: 'center'
        //backgroundColor: 'red'
        //left: window.innerWidth / 2 - 60,
      }}>
        <Button variant="danger" style={{
          backgroundColor: '#40B7AC',
          borderColor: '#40B7AC'
        }} onClick={openSearchModal}>ابحث عن كتاب</Button>
      </div>
      </div>
  )
});

export default SearchBooks;
