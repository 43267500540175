
import './App.css';


import { createContext } from "react";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Store from "./stores";
import RootNavigator from "./navigation/RootNavigator";
export const StoreContext = createContext({});


function App() {
  return (
      <StoreContext.Provider value={Store}>
        <RootNavigator />
        <ToastContainer />
      </StoreContext.Provider>
  );
}

export default App;
