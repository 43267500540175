import React, { useContext, useEffect, useRef, useState } from 'react';

import _ from 'lodash'
import Button from "react-bootstrap/Button";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import '../App.css';
import { getVenue } from "@mappedin/mappedin-js";
import Select from "react-select";
import { StoreContext } from "../App";
import Icon from "react-web-vector-icons";

import $ from "jquery";

import Spinner from 'react-bootstrap/Spinner';

const Settings = React.forwardRef((props, ref) => {

  const [show, setShow] = useState(false);
  const [originalBooks, setOriginalBooks] = useState();
  const [books, setBooks] = useState();
  const [loading, setLoading] = useState(false);

  const [state, setState] = useState({
    busy: true,
    venueData: [],
    init: false
  });

  const myStores = useContext(StoreContext);

  const fetchData = async () => {

    //$('.modal-header button').css('background-color', 'red')
    //$('.modal-header button').css('margin-left', 200)

    if(state.init)
      return;

    setTimeout(async ()=>{
      setState(state=>({...state, busy: true, init: true}));
      console.log ('-->load data' );
      const credentials = myStores.mapStore.credentials;
      const venueData = await getVenue(credentials);
      console.log ('-->venueData', venueData );

      let suggest = [];
      venueData.locations.map((item, index) => {
        suggest.push({ value: item.id, label: item.name });
      });

      setState(state=>({...state, venueData: suggest, busy: false, init: true}));
    }, 100)
  };

  const onSelectDefaultLocation = (e) => {
    localStorage.setItem("mx-map-default-location", JSON.stringify(e));
    //navigate(`/map#/directions?to=&from=${e.value}`)
    myStores.conStore.onSetDefaultLocation( e );
    window.location.href = myStores.mapStore.mapDomain + 'map#/' + 'directions?to=&from=' + e.value
    setShow(false);
  };

  const handleClose = () => {
    setShow(false);
    console.log ('-->close' );
  }
  const handleShow = () => setShow(true);

  const openSettingsModal = () => {
    //let sign = window.prompt('Password: *', '');
    //if(sign === '225500')
    setShow(true)
  }

  const resetCache = () => {
    setLoading(true)
    caches.delete('my-cache')
    caches.delete('max-map-cache-v1')
    caches.delete('max-map-cache-v2')
    caches.delete('max-map-cache-v2').then(function() {
      caches.keys().then((keyList) => Promise.all(keyList.map((key) => caches.delete(key))))
      return caches.open('max-map-cache-v2');
    }).then(function(cache) {
      // Do some caching logic
      //clients.claim();

      navigator.serviceWorker.getRegistrations().then(function(registrations) {
        for(let registration of registrations) {
          console.log ('-->registration', registration );
          if(registration.active.scriptURL)
          {
            registration.unregister();
          }
        }
      });


      setTimeout(()=>{
        window.location.reload();
      }, 3000)
    })

  }

  useEffect(()=>{
    ref.current = {};
    ref.current.show = () => {
      setShow(true)
    }
    ref.current.close = () => {
      setShow(false)
    }

    fetchData();
    let defaultFromLocation = localStorage.getItem('mx-map-default-location');
    defaultFromLocation = JSON.parse(defaultFromLocation);

    if(defaultFromLocation)
    {
      setTimeout(()=>{
        window.location.href = myStores.mapStore.mapDomain + 'map#/' + 'directions?to=&from=' + defaultFromLocation.value
      }, 20)
    }

    else {
      handleShow()
    }
  })

  return (
      <div>
        <Modal show={show} onHide={handleClose}  style={{zIndex: 9999999999999, minHeight: '100%', height: '100%'}}>
          <Modal.Header closeButton>
            <strong>Settings</strong>
            {/*<div  style={{
              //backgroundColor: '#0A1435',
              borderColor: '#0A1435',
              position: 'absolute',
              right: 15,
              bottom: 0,
              height: 40,
              width: 10,
              zIndex: 99999999,

            }} >
              <Icon
                  name='setting'
                  font='AntDesign'
                  color='gray'
                  size={20}
                  style={{

                    //marginLeft: 10
                  }}
              />
            </div>*/}
          </Modal.Header>
          <Modal.Body>
            <div>

              <br />
              <div style={{
                marginTop: -20,
                marginLeft: 10,
                marginBottom: 40,
                //width: 300
              }}>
                {state.busy &&
                <p>loading ....</p>
                }
                {!state.busy &&
                <div >
                  <strong>Please select default location</strong>
                  <Select options={state.venueData} onChange={onSelectDefaultLocation}/>
                </div>
                }
              </div>
              <hr style={{marginBottom: 20}}/>
              <div>
                <Button onClick={resetCache} variant="secondary">Reset</Button>
                <br />
                <samll>After Reset, Please Press CTRL+SHIFT+R</samll>
              </div>
              {loading &&
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              }


              {/*<select>
          <options> location 1</options>
          <options> location 2</options>
          <options> location 3</options>
        </select>*/}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={handleClose} variant="secondary">Close</Button>
          </Modal.Footer>
        </Modal>

        <div  style={{
          //backgroundColor: '#0A1435',
          borderColor: 'red',
          borderWidth: 2,
          position: 'absolute',
          top: 1,
          right: 15,
          bottom: 0,
          height: 40,
          width: 10,
          zIndex: 99999999,
        }} onClick={openSettingsModal}>
          {/*<img src={'./settingIcon.png'} width={20} height={20} />*/}
        </div>

      </div>
  )
});

export default Settings;
