

import Table from 'react-bootstrap/Table';
import { useContext, useEffect, useState } from "react";
import { StoreContext } from "../App";
import { Button } from "react-bootstrap";
import moment from 'moment'
import $ from 'jquery'
import _ from 'lodash'

const Monitor = (props) => {

  const [busy, setBusy] = useState(true)
  const [devices, setDevices] = useState([])

  const myStores = useContext(StoreContext);

  myStores.conStore.socketConnection('monitor')
  myStores.conStore.onSocketMonitorReceive(()=>{
    console.log ('-->myStores.conStore.onSocketMonitorReceive()' );
  })


  useEffect(()=>{

    myStores.conStore.socket.on('ALL_DEVICES_LOAD', (payload) => {
      payload = JSON.parse(payload)
      setDevices(payload.data)
      setBusy(false)

      let origiDevices = payload.data

      myStores.conStore.socket.on('DEVICE_STATUS_CHANGED', (e) => {
        setBusy(true)
          let params = JSON.parse(e)

          let deviceIndex = null
          const deviceInf = origiDevices.filter((row, indx) => {
            deviceIndex = indx
            return row.id === params.id
          });

          if(deviceIndex !== -1)
          {
            console.log ('-->we found device index', deviceIndex );
            origiDevices[deviceIndex] = params

            //origiDevices[0] = 1
            //origiDevices[1] = 2

            console.log ('-->origiDevices', origiDevices );

            setDevices([])
            setTimeout(()=>{
              //console.log ('-->aaa',devices, Math.floor((Math.random() * 100) + 1) );
              setDevices(devices => {
                origiDevices[deviceIndex] = params
                return origiDevices
              });
              //setDevices(devices)

            }, 100)
          }
          else
          {
            setDevices([])
            setTimeout(()=>{
            setDevices(devices => {
              origiDevices.push(params)
              return origiDevices
            });
            }, 100)
            //setDevices(devices);
          }


        setBusy(false)
      });
      setBusy(false)
    });




  },[busy, devices])

  return (
      <div style={{
        margin: 20
      }}>

        <br />
        <h3>Monitor Devices</h3>
        <br />
        {busy &&
          <strong>Loading ..</strong>
        }


        <Table striped bordered hover responsive hover={true}>
          <thead>
            <th>Device</th>
            <th>Status</th>
            <th>Last Seen</th>
            {/*<th>Info</th>*/}
          </thead>
          <tbody>
          {devices.map((row, index)=>{
            let deviceLocation = {}
            try {
              deviceLocation = JSON.parse(row.location_vars)
            }catch (e){}
            if(deviceLocation === null)
              deviceLocation = {
                label: ''
              }
            return (
                <tr id={'device_' + row.id} key={index}>
                  <td>
                    {deviceLocation &&
                    <>
                      {deviceLocation.value ?
                          <a target={'_blank'} href={`https://mapdesktop.saudibookfairs.com/#/directions?to=${deviceLocation.value}&from=`}>#{row.id} {deviceLocation.label}</a>
                          :
                          <div>#{row.id} {deviceLocation.label}</div>
                      }
                    </>
                    }
                  </td>
                  {row.socket === 1 ?
                    <td style={{backgroundColor: 'green', color: 'white'}}>ONLINE</td>
                      :
                    <td style={{backgroundColor: 'RED', color: 'white'}}>OFFLINE</td>
                  }
                  <td>{moment(row.last_seen).format('YYYY-MM-DD HH:mm:ss')}</td>
                  {/*<td>
                    <strong></strong>
                    {row.socket === 1 ?
                      <Button>Yes - Update </Button>
                        :
                        <strong>No</strong>
                    }
                  </td>*/}
                </tr>
            )
          })}
          </tbody>
        </Table>
      </div>
  )
}


export default Monitor;
