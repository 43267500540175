
import MapStore from './MapStore'
import ConStore from './ConStore'

const mapStore = new MapStore();
const conStore = new ConStore();

export default {
  mapStore: mapStore,
  conStore: conStore
}

