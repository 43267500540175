import { createContext } from "react";

import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";


import Home from '../screens/Home'
import Map from '../screens/Map'
import Monitor from '../screens/Monitor'

const RootNavigator = () => {
  return (
        <BrowserRouter basename="/">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/Map" element={<Map />} />
            <Route path="/Monitor" element={<Monitor />} />
          </Routes>
        </BrowserRouter>
  )
};


export default RootNavigator;
