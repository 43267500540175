import { v4 as uuidv4 } from "uuid";
import io from "socket.io-client";
import axios from 'axios';

//const SOCKET_ENDPOINT = "https://localhost:2053/v1/device";
const SOCKET_ENDPOINT = "https://ln1.in:2053/v1/device";

class conStore {

  constructor(){

  }

  socketConnection = (type) => {
    this.storageDeviceId = localStorage.getItem('imx-device-id');
    if(!this.storageDeviceId)
      this.storageDeviceId = localStorage.setItem('imx-device-id', uuidv4() )

    //console.log ('-->this.socket', this.socket );
    if(this.socket)
      return

    let fromFromStorage = localStorage.getItem('mx-map-default-location');

    const socket = io( SOCKET_ENDPOINT, {
      forceNode:true,
      transports: ['websocket'],
      origins: '*:*',
      auth: {
        'imx_device_id': localStorage.getItem('imx-device-id'),
        'imx_type': type,
        'imx_version': 1,
        'imx_location_vars': fromFromStorage
      },
    });


    console.log ('-->socket', socket );

    this.socket = socket;

    console.log ('-->this.socket', this.socket );

    var x = this.socket.$emit;

    socket.$emit = function(){
      var event = arguments[0];
      var feed  = arguments[1];

      //Log
      console.log(event + ":" + feed);

      //To pass listener
      x.apply(this, Array.prototype.slice.call(arguments));
    };

    this.socket.on('connect', () => {
      console.log ('-->connect', this.socket.id, this.storageDeviceId );
      if(type === 'monitor')
      {
        this.onMonitorConnect()
      }
      if(type === 'map')
      {
        this.onMapReciveUpdate()
      }
    });

    this.socket.on('disconnect', () => {

    });

  }

  clean(){
    this.socket.off('connect');
    this.socket.off('disconnect');
  }

  onMapReciveUpdate = () => {
    if(!this.socket)
      return


    this.socket.on('RESET_UPDATE', () => {

      caches.delete('max-map-cache-v1').then(function() {
        caches.keys().then((keyList) => Promise.all(keyList.map((key) => caches.delete(key))))
        return caches.open('max-map-cache-v1');
      }).then(function(cache) {
        // Do some caching logic
        //clients.claim();

        navigator.serviceWorker.getRegistrations().then(function(registrations) {
          for(let registration of registrations) {
            console.log ('-->registration', registration );
            if(registration.active.scriptURL)
            {
              registration.unregister();
            }
          }
        });

        setTimeout(()=>{
          window.location.reload();
        }, 100)
      })
    });

    //window.location.reload();
  }

  onSetDefaultLocation = (e) => {
    if(!this.socket)
      return
    this.socket.emit('CHOSE_DEFAULT_LOCATION', JSON.stringify(e))
  }

  onMonitorConnect = () => {
    //call list api
    console.log ('-->onMonitorConnect' );
  }

  onSocketMonitorReceive = () => {
    //this.socket.on()
  }

  onDeviceStatusChange = () => {

  }

  getAllBooks = async () => {
    let url = 'https://mbfapi.saudibookfairs.com/Api/ar/Publishers/AllBooks'
    const request = {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json', }
    };
    const response = await fetch(url, request);
    return await response.json();
  }

}

export default conStore;
