
import React, { useState, useEffect } from 'react';
import io from 'socket.io-client';

import { createContext } from "react";
import { v4 as uuidv4 } from 'uuid';

export const StoreContext = createContext({});

const SocketIo = () => {

  return (
      <></>
  );
}

export default SocketIo;

